<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-between"
          >
            <div>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
            <b-button
              variant="primary"
              :to="{ name: 'add-store-suppliers', params: { store_id: $route.params.store_id, establishment_id: $route.params.establishment_id} }"
            >
              <span class="text-nowrap">Agregar proveedor</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="suppliers"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Supplier name data -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Raffle Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="(data.item.active_status === true) ? 'success' : 'warning'"
          >
            {{ data.item.active_status | status }}
          </b-badge>
        </template>

        <!-- Column: Raffle Date -->
        <template #cell(current_loan_balance)="data">
          $ {{ data.value }}
        </template>

        <template #cell(payment)="data">
          <b-button
            variant="success"
            size="sm"
            :to="{ name: 'store-supplier-payment', params: { store_supplier_id: data.item.id } }"
          >
            Pago a proveedor
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'edit-store-suppliers', params: { store_id: $route.params.store_id, store_supplier_id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar proveedor</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ pagination.total_objects }} de {{ suppliersCount }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="pagination.page"
              :total-rows="pagination.total_objects"
              :per-page="pagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="(value)=>{fetchStoreSuppliers({ by_store: $route.params.store_id, meta: { pagination: {page: value, per_page: pagination.per_page} } })}"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink, BButton,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from 'lodash'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      searchQuery: null,
      is_banner: false,
      is_featured: false,
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'current_loan_balance',
          label: 'Saldo deudor',
        },
        {
          key: 'payment',
          label: 'Pago',
        },
        {
          key: 'actions',
          label: 'Herramientas',
        },
      ],
      dataMeta: {
        from: 100,
        to: 200,
        of: 5,
      },
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers', 'pagination', 'suppliersCount']),
  },
  watch: {
    searchQuery: debounce(function searchQuery(query) {
      this.fetchStoreSuppliers({
        by_name: query,
        // by_active_status: 1,
        by_store: this.$route.params.store_id,
      })
    }, 250),
  },
  mounted() {
    this.fetchStoreSuppliers({
      // by_active_status: 1,
      by_store: this.$route.params.store_id,
    })
  },
  methods: {
    ...mapActions('suppliers', ['fetchStoreSuppliers', 'editStoreSupplier']),
    // eslint-disable-next-line camelcase
    showDeleteConfirmationModal(store_supplier) {
      this.$bvModal.msgBoxConfirm('Porfavor confirma que deseas eliminar el proovedor actual', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            const storeSupplierToDelete = {
              // eslint-disable-next-line camelcase
              ...store_supplier,
              active_status: false,
            }
            this.editStoreSupplier(storeSupplierToDelete)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Proveedor eliminado',
                    icon: 'Trash2Icon',
                    variant: 'success',
                  },
                })
                this.fetchStoreSuppliers({
                  // by_active_status: 1,
                  by_store: this.$route.params.store_id,
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
</style>
